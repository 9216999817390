<template>
  <div>
    <div class="custom-answer-container mt-2 text-md">
      <div
        v-for="(answer,index) in answers"
        :key="index"
        class="custom-answer-option md:ml-4 xl:ml-10 2xl:ml-14 pt-2 md:pt-0"
      >
        <label class="cursor-pointer">
          <input
            type="checkbox"
            name="eval_q13"
            class="cursor-pointer"
            v-model="answer.checked"
          >
          <span
            class="custom-label-text inline-block align-top pl-4"
            style="max-width: 80%;"
          >{{ answer.label }}</span>
          <input
            v-if="answer.checked && answer.openEnded"
            type="text"
            name="eval_q12_other"
            v-model="answer.openEndedAnswer"
            class="custom-open-ended-input mt-2"
          >
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
  props: ['answers', 'id'],
  methods: {
    setAnswer() {
      this.$emit('answer', this.markedAnswers);
    },
  },
  computed: {
    ...mapGetters(['evaluationQuestions', 'getAnswerById']),
    markedAnswers() {
      const filteredAnswers = this.answers.filter((answer)=>{
        return answer.checked;
      });

      return filteredAnswers.map((el)=>{
        let label = el.label;
        if (el.openEnded) {
          label = `${label}: ${el.openEndedAnswer ? el.openEndedAnswer : ''}`;
        }
        return label;
      });
    },
  },
  watch: {
    markedAnswers() {
      this.setAnswer();
    },
    evaluationQuestions() {
      const foundAnswer = this.getAnswerById(this.id);
      if (foundAnswer.answerChoice) {
        const parsedAnswers = foundAnswer.answerChoice.split('::');
        this.answers.forEach((answerChoice)=>{
          if (parsedAnswers.indexOf(answerChoice.label)!==-1) {
            answerChoice.checked = true;
          }
        });
        // this.answerChoice = foundAnswer.answerChoice;
      }
    },
  },
};
</script>

<style>
  .custom-answer-container {
    margin-top: 1rem;
    font-size: 1rem;
  }

  .custom-answer-option {
    margin-left: 1rem;
    padding-top: 0.5rem;
  }

  .custom-label-text {
    display: inline-block;
    vertical-align: top;
    padding-left: 1rem;
    max-width: 80%;
  }

  .custom-open-ended-input {
    width: 50%; /* Ajuste conforme necessário */
    padding: 0.75rem;
    font-size: 1rem;
    font-weight: normal;
    color: #4a5568; /* Altere a cor do texto conforme necessário */
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #cbd5e0; /* Altere a cor da borda conforme necessário */
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-top: 0.5rem;
  }

  .custom-open-ended-input:focus {
    color: #4a5568; /* Altere a cor do texto ao focar conforme necessário */
    background-color: #ffffff;
    border-color: #3182ce; /* Altere a cor da borda ao focar conforme necessário */
    outline: none;
  }
</style>