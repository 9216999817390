<template>
  <div>
    <div class="custom-options-container flex mt-2 text-left md:text-center text-md ml-6 md:ml-0">
      <label
        v-for="(option, index) in answers"
        :key="index"
        class="custom-label-container"
      >
        <input
          type="radio"
          :name="id"
          v-model="answerChoice"
          class="custom-input cursor-pointer"
          :value="option.value"
        >
        {{ option.label }}
      </label>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
  props: ['answers', 'id'],
  methods: {

  },
  computed: {
    ...mapGetters(['evaluationQuestions', 'getAnswerById']),
    answerChoice: {
      get() {
        let choice = null;
        const foundAnswer = this.getAnswerById(this.id);
        if (foundAnswer.answerChoice) {
          choice = foundAnswer.answerChoice;
        }
        return choice;
      },
      set(choice) {
        this.$emit('answer', choice);
      },
    },
  },
};
</script>

<style>
  .custom-options-container {
    display: flex;
    margin-top: 0.5rem;
    text-align: left;
  }

  .custom-label-container {
    display: flex;
    align-items: center;
    margin-right: 1rem; /* Ajuste conforme necessário para espaçamento entre opções */
  }

  .custom-input {
    cursor: pointer;
    margin-right: 0.25rem; /* Ajuste conforme necessário para espaçamento entre o input e o label */
  }
</style>