<template>
  <div
    class="custom-question-container px-10 mt-4 pt-2 rounded w-full"
    :class="[{'border-t-2': !first}]"
    v-if="!hidden"
  >
    <div
      class="custom-question-text text-lg border-red-200 pl-2"
      :class="[{'border-l-4': notAnsweredButRequired && checking}]"
    >
      <p>{{ questionData.data.header }}</p>
      <multiple
        @answer="setAnswer"
        :answers="questionData.data.options"
        :id="questionData.id"
        v-if="questionData.type === 'multiple'"
      />
      <true-false
        @answer="setAnswer"
        :answers="questionData.data.options"
        :id="questionData.id"
        v-if="questionData.type === 'trueFalse'"
      />
      <check-list
        @answer="setAnswer"
        :answers="questionData.data.options"
        :id="questionData.id"
        v-if="questionData.type === 'checkList'"
      />
      <open-ended
        @answer="setAnswer"
        v-if="questionData.type === 'openEnded'"
      />
      <eval-select
        @answer="setAnswer"
        :answers="questionData.data.options"
        :id="questionData.id"
        v-if="questionData.type === 'select'"
      />
    </div>
  </div>
</template>

<script>
import multiple from './multiple.vue';
import openEnded from './openEnded.vue';
import checkList from './checkList.vue';
import trueFalse from './trueFalse.vue';
import evalSelect from './select.vue';
import {mapGetters} from 'vuex';
export default {
  props: ['questionData', 'first'],
  components: {
    multiple,
    openEnded,
    checkList,
    trueFalse,
    evalSelect
  },
  methods: {
    setAnswer(answer) {
      this.$store.dispatch('setAnswer', {id: this.questionData.id, answer});
    },
  },
  computed: {
    ...mapGetters([
      'getAnswerById',
      'checking',
    ]),
    notAnsweredButRequired() {
      const question = this.getAnswerById(this.questionData.id);
      return question.data.required && !question.answerChoice;
    },
    hidden() {
      let hideQuestion = true;
      if (!this.questionData.data.hidden) {
        return this.questionData.data.hidden;
      }

      if (this.questionData.showRequirements) {
        const answer = this.getAnswerById(this.questionData.showRequirements.questionId);
        hideQuestion = !(answer.answerChoice === this.questionData.showRequirements.answerIndex.toString());
      }
      return hideQuestion;
    },
  },
};
</script>

<style>
  .custom-question-container {
    margin-top: 1rem;
    width: 100%;
    border-top: 2px solid transparent;
  }

  .custom-question-container.border-t-2 {
    border-top-color: #e5e7eb; /* Altere a cor conforme necessário */
  }

  .custom-question-text {
    font-size: 1.125rem; /* Ajuste conforme necessário */
    border-left: 4px solid transparent;
  }

  .custom-question-text.border-l-4 {
    border-left-color: #e53e3e; /* Altere a cor conforme necessário */
  }
</style>