<template>
  <div>
    <div class="custom-select-container flex flex-wrap mt-2 text-left md:text-center text-md ml-6 md:ml-0">
      <select v-model="answerChoice" class="custom-select-picker">
        <option
          v-for="(opt,index) in answers"
          :key="index"
          :value="opt.value"
        >
          {{ opt.label }}
        </option>  
      </select>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
  props: ['answers', 'id'],
  methods: {

  },
  computed: {
    ...mapGetters(['evaluationQuestions', 'getAnswerById']),
    answerChoice: {
      get() {
        let choice = null;
        const foundAnswer = this.getAnswerById(this.id);
        if (foundAnswer.answerChoice) {
          choice = foundAnswer.answerChoice;
        }
        return choice;
      },
      set(choice) {
        this.$emit('answer', choice);
      },
    },
  },
};
</script>

<style>
  .custom-select-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    text-align: left;
  }

  .custom-select-picker {
    padding: 0.5rem;
    font-size: 1rem;
    color: #4a5568; /* Altere a cor do texto conforme necessário */
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #cbd5e0; /* Altere a cor da borda conforme necessário */
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-top: 0.5rem;
    width: 100%; /* Ajuste conforme necessário */
  }

  .custom-select-picker:focus {
    color: #4a5568; /* Altere a cor do texto ao focar conforme necessário */
    background-color: #ffffff;
    border-color: #3182ce; /* Altere a cor da borda ao focar conforme necessário */
    outline: none;
  }
</style>